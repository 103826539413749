/**
 * Check touch events support
 * @returns {boolean}
 */
export const isTouchEnabled = () => "ontouchstart" in window || navigator.maxTouchPoints > 0

/**
 * Determine if an element should be lazy loaded
 * @param {Element} element
 * @returns {boolean}
 */
export const shouldBeLazyLoaded = (element) => {
    const triggerOffset = Math.round(window.innerHeight * 1.5)
    const { top: elementTop } = getCoords(element)
    const windowScrollYPos = Math.round(window.scrollY)
    const triggerPosition = Math.round(elementTop) - triggerOffset

    return windowScrollYPos >= triggerPosition
}

/**
 * Get element X and Y position on the page
 * @param {Element} element
 * @returns {{top: number, left: number}}
 */
export const getCoords = (element) => {
    const box = element.getBoundingClientRect()
    const body = document.body
    const docEl = document.documentElement
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft
    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0
    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top, left }
}

/**
 * Get the current year
 * @returns {number}
 */
export const getCurrentYear = () => new Date().getFullYear()

/**
 * Open Login/Register popup on register tab
 * @param {string} type
 */
export const openLoginRegistrationPopup = (type) => {
    window.trialRegistrationForm = true
    setTimeout(() => {
        const registerPopup = document.getElementById("register-popup")
        const aContainer = document.getElementById("a-container")
        const bContainer = document.getElementById("b-container")
        const switchCnt = document.getElementById("switch-cnt")
        const switchC1 = document.getElementById("switch-c1")
        const switchC2 = document.getElementById("switch-c2")
        const signInFormMobile = document.getElementById("signInFormMobile")
        const signUpFormMobile = document.getElementById("signUpFormMobile")

        registerPopup.classList.add("popup-open")
        registerPopup.classList.remove("close-important", "start-position")

        if (type === "registration") {
            aContainer.classList.remove("is-txl")
            bContainer.classList.remove("is-txl", "is-z200")
            switchCnt.classList.remove("is-txr")
            switchC1.classList.remove("is-hidden")
            switchC1.classList.add("active")
            switchC2.classList.add("is-hidden")
            switchC2.classList.remove("active")

            signInFormMobile.className = "not-active"
            signUpFormMobile.className = "active"
        } else {
            signInFormMobile.className = "active"
            signUpFormMobile.className = "not-active"
        }
    }, 420)
}

/**
 * Sort object by key from a to z
 * @param {object} obj
 * @returns {object}
 */
export const sortObjByKey = (obj) => {
    return Object.keys(obj)
        .sort()
        .reduce((result, key) => {
            result[key] = obj[key]
            return result
        }, {})
}

/**
 * Smooth scroll to position
 * @param {number} pos
 * @param {number} time
 */
export const scrollToSmoothly = (pos, time = 500) => {
    const currentPos = window.pageYOffset
    let start = null

    window.requestAnimationFrame(function step(currentTime) {
        start = start || currentTime
        const progress = currentTime - start
        const newPos =
            currentPos < pos
                ? ((pos - currentPos) * progress) / time + currentPos
                : currentPos - ((currentPos - pos) * progress) / time

        window.scrollTo(0, newPos)

        if (progress < time) {
            window.requestAnimationFrame(step)
        } else {
            window.scrollTo(0, pos)
        }
    })
}

/**
 * Change currency format
 * @param value
 * @returns {string}
 */
export const toCurrency = (value) => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(value)
}

/**
 * Load translations for a specific language
 * @returns {Promise<*|null>}
 */
export async function loadTranslations() {
    if (window.translationsData) {
        return Promise.resolve(window.translationsData)
    }

    return new Promise((resolve) => {
        const checkTranslations = () => {
            if (window.translationsData) {
                resolve(window.translationsData)
            } else {
                setTimeout(checkTranslations, 50)
            }
        }
        checkTranslations()
    })
}
